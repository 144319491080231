/**
 * @description Actions used for dispatch of Filter Reducer
 * @enum {string}
 */
const FilterActions = {
  UPDATE_FILTER: 'UPDATE_FILTER',
  CLEAR_FILTER: 'CLEAR_FILTER',
  RECEIVED_DEFAULT_ASSIGNMENT_FILTER: 'RECEIVED_DEFAULT_ASSIGNMENT_FILTER',
  RECEIVED_TIME_ZONE_SETTING: 'RECEIVED_TIME_ZONE_SETTING',
  ENABLE_SWAP_MODE_FILTER: 'ENABLE_SWAP_MODE_FILTER',
  CLEAR_SWAP_MODE_FILTER: 'CLEAR_SWAP_MODE_FILTER',
  RECEIVED_OPERATING_AIRLINE_BY_ROLE: 'RECEIVED_OPERATING_AIRLINE_BY_ROLE',
};

/**
 * @description Actions used for sorting the flights
 * @enum {string}
 */
const SortActions = {
  DEFAULT_PRIORITY: 'DEFAULT_PRIORITY',
  ETD_PRIORITY: 'ETD_PRIORITY',
  ETA_PRIORITY: 'ETA_PRIORITY',
};

const FocusedFlightLegActions = {
  SET_FOCUSED_FLIGHT_LEG: 'SET_FOCUSED_FLIGHT_LEG',
  SET_IS_FOCUSED: 'SET_IS_FOCUSED',
  CLEAR_FOCUSED_FLIGHT_LEG: 'CLEAR_FOCUSED_FLIGHT_LEG',
  HIGHLIGHT_FOCUSED_FLIGHT_LEG: 'HIGHLIGHT_FOCUSED_FLIGHT_LEG',
};

/**
 * @description Actions used for dispatch of Hide Cancelled Flights Reducer
 * @enum {string}
 */
const HideCancelledFlightsActions = {
  TOGGLE_HIDE_CANCELLED_FLIGHTS: 'TOGGLE_HIDE_CANCELLED_FLIGHTS',
};

/**
 * @description Selected Flight State used for dispatch of Selected Flight Reducer
 * @enum {string}
 */
const SelectedFlightDetailsAction = {
  UPDATE: 'UPDATE_DETAILS',
  CLEAR: 'CLEAR_DETAILS',
};

const ConfigFileAction = {
  OPEN_SAVECONFIG: 'OPEN_SAVECONFIG',
  CLOSE_SAVECONFIG: 'CLOSE_SAVECONFIG',
  UPDATE_SAVECONFIG: 'UPDATE_SAVECONFIG',
  UPDATE_ACTIVE_CONFIGURATION_ID: 'UPDATE_ACTIVE_CONFIGURATION_ID',
  UPDATE_SINGLEVIEWTYPE: 'UPDATE_SINGLEVIEWTYPE',
};
/**
 * @description Multi Selected Flight State used for dispatch of Selected Flight Reducer
 * @enum {string}
 */
const MultiSelectedFlightGroup = {
  UPDATE: 'UPDATE_FLIGHT_GROUP',
  REMOVE: 'REMOVE_FLIGHT_GROUP',
  REMOVEALL: 'REMOVE_ALL_FLIGHT_GROUP',
  UPDATE_AIRCRAFT: 'UPDATE_AIRCRAFT',
  SWAP_AIRCRAFT: 'SWAP_AIRCRAFT',
};
/**
 * @description Auto refresh State used for dispatch of Auto Refresh Reducer
 * @enum {string}
 */
const AutoRefreshAction = {
  UPDATE_GANTT_CHART: 'UPDATE_GANTT_CHART',
  UPDATE_FLIGHT_DETAIL_PANE: 'UPDATE_FLIGHT_DETAIL_PANE',
};

/**
 * @description Swap Mode State used for dispatch of Swap Mode Reducer
 * @enum {string}
 */
const SwapModeActionKeys = {
  ENABLE_SWAP_MODE: 'ENABLE_SWAP_MODE',
  SWAP_FLIGHT: 'SWAP_FLIGHT',
  MOVE_FLIGHT: 'MOVE_FLIGHT',
  CLEAR_SWAP_MODE: 'CLEAR_SWAP_MODE',
  SAVED_SWAP_FLIGHT: 'SAVED_SWAP_FLIGHT',
  SWAP_FLIGHT_LINE: 'SWAP_FLIGHT_LINE',
  UNDO_FLIGHT: 'UNDO_FLIGHT',
  REDO_FLIGHT: 'REDO_FLIGHT',
  DISCARD_FLIGHT: 'DISCARD_FLIGHT',
  CLEAR_SWAP_DATA: 'CLEAR_SWAP_DATA',
  REMOVE_FLIGHT: 'REMOVE_FLIGHT',
};

/**
 * @description Crosshair State used for dispatch of crosshair Mode Reducer
 * @enum {string}
 */
const CrosshairActionKeys = {
  UPDATE_CROSSHAIR_MODE: 'UPDATE_CROSSHAIR_MODE',
  UPDATE_CROSSHAIR_COUNT: 'UPDATE_CROSSHAIR_COUNT',
  UPDATE_ACTIVE_CROSSHAIR: 'UPDATE_ACTIVE_CROSSHAIR',
  UPDATE_CROSSHAIR_POSITION: 'UPDATE_CROSSHAIR_POSITION',
  CLEAR_CROSSHAIR_STORE: 'CLEAR_CROSSHAIR_STORE',
};

/**
 * @description Actions for Drag and Drop Reducer
 * @enum {string}
 */
const DragDropAction = {
  START_DRAG: 'START_DRAG',
  END_DRAG: 'END_DRAG',
  HOVER: 'HOVER',
};

/**
 * @description Actions for Flight Puck Ref Reducer
 * @enum {string}
 */
const FlightPuckRefAction = {
  ADD_FLIGHT_PUCK_REF: 'ADD_FLIGHT_PUCK_REF',
  REMOVE_FLIGHT_PUCK_REF: 'REMOVE_FLIGHT_PUCK_REF',
};

/**
 * @description Actions for Summary Panel Reducer
 * @enum {string}
 */
const SummaryPanelAction = {
  UPDATE_SUMMARY: 'UPDATE_SUMMARY',
  CLEAR_SUMMARY: 'CLEAR_SUMMARY',
  UPDATE_DETAILS: 'UPDATE_DETAILS',
  CLEAR_DETAILS: 'CLEAR_DETAILS',
  UPDATE_PANEL_MODE: 'UPDATE_PANEL_MODE',
  CLEAR: 'CLEAR',
};

const WindowManagerAction = {
  ADD_VIEW_WINDOW: 'ADD_VIEW_WINDOW',
  REMOVE_VIEW_WINDOW: 'REMOVE_VIEW_WINDOW',
  UPDATE_VIEW_WINDOW: 'UPDATE_VIEW_WINDOW',
  SET_ACTIVE_VIEW: 'SET_ACTIVE_VIEW',
  SET_MINIMIZED_VIEW: 'SET_MINIMIZED_VIEW',
  REMOVE_MINIMIZED_VIEW: 'REMOVE_MINIMIZED_VIEW',
  SET_MAXIMIZED_VIEW: 'SET_MAXIMIZED_VIEW',
  REMOVE_MAXIMIZED_VIEW: 'REMOVE_MAXIMIZED_VIEW',
  RENAME_VIEW_WINDOW: 'RENAME_VIEW_WINDOW',
  SET_DRAGGED_MINIMIZED_VIEW: 'SET_DRAGGED_MINIMIZED_VIEW',
  ARRANGE_VIEWS_CASCADE: 'ARRANGE_VIEWS_CASCADE',
  ARRANGE_VIEWS_GRID: 'ARRANGE_VIEWS_GRID',
  ARRANGE_VIEWS_COLUMNS: 'ARRANGE_VIEWS_COLUMNS',
  ARRANGE_VIEWS_ROWS: 'ARRANGE_VIEWS_ROWS',
};

const SearchFlightAction = {
  SET_SEARCH_FLIGHT_NUMBER: 'SET_SEARCH_FLIGHT_NUMBER',
  SET_CURRENT_INDEX: 'SET_CURRENT_INDEX',
  SET_SHOW_MATCHES: 'SET_SHOW_MATCHES',
  SET_SEARCH_RESULTS: 'SET_SEARCH_RESULTS',
};

// Action states for the reducer
const RootAction = {
  UPDATE_ROOTSTATE: 'UPDATE_ROOTSTATE',
};

// Action states for the reducer
const TableListActions = {
  RECEIVED_DISPLAY_DATA: 1,
  SORT_DATA: 2,
  INCREMENT_DISPLAY_COUNT: 3,
  CHANGE_DISPLAY_COUNT: 4,
  SEARCH_FLIGHT_NUMBER: 5,
  INITIAL_DISPLAY_COUNT: 25,
  DISPLAY_COUNT_INCREMENT: 50,
};

//Action states for GanttViewConfiguration reducer
const ViewConfigurationAction = {
  UPDATE_VIEWCONFIGURATION: 'UPDATE_VIEWCONFIGURATION',
  UPDATE_NUMBEROFAIRCRAFTS: 'UPDATE_NUMBEROFAIRCRAFTS',
  UPDATE_HOURSAFTER: 'UPDATE_HOURSAFTER',
  UPDATE_HOURSBEFORE: 'UPDATE_HOURSBEFORE',
  UPDATE_PUCKSIZE: 'UPDATE_PUCKSIZE',
  UPDATE_TIMELINEHOURS: 'UPDATE_TIMELINEHOURS',
};

Object.freeze(CrosshairActionKeys);
Object.freeze(FilterActions);
Object.freeze(SortActions);
Object.freeze(TableListActions);
Object.freeze(SelectedFlightDetailsAction);
Object.freeze(AutoRefreshAction);
Object.freeze(SwapModeActionKeys);
Object.freeze(DragDropAction);
Object.freeze(FlightPuckRefAction);
Object.freeze(MultiSelectedFlightGroup);
Object.freeze(SummaryPanelAction);
Object.freeze(FocusedFlightLegActions);
Object.freeze(ConfigFileAction);
Object.freeze(RootAction);
Object.freeze(WindowManagerAction);
Object.freeze(SearchFlightAction);
Object.freeze(ViewConfigurationAction);

export {
  HideCancelledFlightsActions,
  FilterActions,
  SortActions,
  SelectedFlightDetailsAction,
  SwapModeActionKeys,
  AutoRefreshAction,
  DragDropAction,
  CrosshairActionKeys,
  FlightPuckRefAction,
  MultiSelectedFlightGroup,
  SummaryPanelAction,
  FocusedFlightLegActions,
  TableListActions,
  ConfigFileAction,
  RootAction,
  WindowManagerAction,
  SearchFlightAction,
  ViewConfigurationAction,
};

import { useMutation, useQueryClient } from 'react-query';
import { swapsWarningsApi } from '../../services/apiClient/swapsWarningsApi/swapsWarningsApi';

/**
 * Custom hook to handle mutation for swap warnings.
 *
 * @param {Function} handleErrorState - Function to handle error state.
 * @param {Function} updateSwapWarningsData - Function to update swap warnings data.
 * @returns {Function} swapWarningMutate - Function to trigger the mutation.
 */
export const useSwapWarningMutate = (handleErrorState, updateSwapWarningsData) => {
  const queryClient = useQueryClient();
  const { mutate: swapWarningMutate } = useMutation(swapsWarningsApi, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('swapWarnings');
      if (!data) {
        handleErrorState();
      } else {
        updateSwapWarningsData(data);
      }
    },
    onError: (error) => {
      handleErrorState();
      console.error(error);
    },
  });

  return swapWarningMutate;
};
